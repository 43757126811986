@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
html {
}

/* Tablet (xs)  */
@media only screen and (min-width: 280px) {
  .innerDiv {
    padding-left: 30px;
    padding-right: 30px;
  }
  header .innerDiv {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .small-footer{
    &   .innerDiv {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
.video-iframe {
  & iframe {
    height: 250px;
  }
}
/* Tablet (sm)  */
@media only screen and (min-width: 640px) {
  .innerDiv {
    padding-left: 60px;
    padding-right: 60px;
  }
  header .innerDiv {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .footer-menu {
    display: block !important;
  }
  .message-box {
    transform: translate(-50%, -50%);
  }
  .video-iframe {
    & iframe {
      height: 64vh;
    }
  }
}

/* Tablet (md)  */
@media only screen and (min-width: 768px) {
  .innerDiv {
    padding-left: 60px;
    padding-right: 60px;
  }
  header .innerDiv {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .story-container {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

/* Desktop (lg) */
@media only screen and (min-width: 1024px) {
  .innerDiv {
    padding-left: 80px;
    padding-right: 80px;
  }
  header .innerDiv {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 40px;
    padding-right: 40px;
  }
  .story-container {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
}

/* Desktop (xl)  */
@media only screen and (min-width: 1280px) {
  .innerDiv {
    max-width: calc(1280px + 160px);
    margin: 0 auto;
    padding-left: 80px;
    padding-right: 80px;
  }
  header .innerDiv {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* Desktop (2xl)  */
@media only screen and (min-width: 1536px) {
  .innerDiv {
    max-width: 1280px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  header .innerDiv {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* Max Width 2560px */
main,
header,
footer {
  max-width: 2560px;
  margin: 0 auto;
}

// circle Animation

.circleAnimation {
  animation: circle 40s infinite alternate ease-in-out;
}

@keyframes circle {
  from {
    transform: translateY(100px);
  }
  to {
    transform: translate(400px);
  }
}

.circleAnimation2 {
  animation: circle2 40s infinite alternate ease-in-out;
}

@keyframes circle2 {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(-400px);
  }
}

.vectiorAnimation {
  animation: vector 8s infinite alternate ease-in-out;
}

@keyframes vector {
  from {
    transform: rotate(10deg);
  }
  to {
    transform: translateX(20deg);
  }
}

.vectiorAnimation2 {
  animation: vector2 8s infinite alternate ease-in-out;
}

@keyframes vector2 {
  from {
    transform: rotate(40deg);
  }
  to {
    transform: translateX(0deg);
  }
}

// Nav
@media (max-width: 786px) {
  .navLinks {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 10;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    // opacity: 0;
    pointer-events: none;
    transition: 0.5s all;
  }
  .navLinks.open {
    width: 100vw;
    height: 100vh;
    opacity: 1;
    pointer-events: all;
    padding-top: 2rem;
    gap: 6rem;
    z-index: 100;
    transform: translate(-50%, 72px);
  }
}

// custom Checkbox
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    border: 2px solid #f9c933;
    border-radius: 3px;
  }

  // Box hover
  &:hover + label:before {
    background: #f9c933;
  }

  // Box checked
  &:checked + label:before {
    background: #f9c933;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: black;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 black, 4px 0 0 black, 4px -2px 0 black, 4px -4px 0 black,
      4px -6px 0 black, 4px -8px 0 black;
    transform: rotate(45deg);
  }
}

.active {
  // border-bottom: #f9c933 2px solid;
}

// headroom
.headroom {
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: all 0.5s ease-in-out;
}
.headroom--unfixed {
  position: relative;
  transform: translateY(0);
  z-index: -1 !important;
}
.headroom--scrolled {
  transition: transform 200ms ease-in-out;
}
.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
  z-index: -1 !important;
}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
  z-index: -1 !important;
}
.headroom.headroom--pinned.headroom--scrolled {
  z-index: 99 !important;
}
@media (max-width: 767px) {
  .goals-section {
    & img {
      width: 120px !important;
      height: 120px !important;
    }
  }
}
@media (max-width: 480px) {
  .logo-img {
    & span {
      display: block !important;
    }
    & img {
      width: 100px !important;
      height: auto !important;
    }
  }
  .goals-section {
    & img {
      width: 100px !important;
      height: 100px !important;
    }
  }
}
.hero-image {
  & span {
    display: block !important;
  }
}
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.category-img {
  & span {
    display: block !important;
  }
}
.custom-x-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.custom-x-scroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.custom-track::-webkit-scrollbar-track {
  background-color: darkgrey;
  border-radius: 10px;
}
.job-image {
  & span {
    vertical-align: bottom;
  }
}

.vision-section {
  background: url("../images/vision-bg.png");
}

@media (max-width: 640px) {
  .recommended-section {
    & .category-img {
      & span {
        display: block !important;
      }
    }

    & img {
      width: 80px !important;
      height: 80px !important;
    }
  }
  .feature-section {
    & span {
      display: block !important;
    }
    & img {
      width: 20px !important;
      height: 20px !important;
    }
  }
  .paly-button {
    width: 16px !important;
    height: 16px !important;
  }
}
.courseplay-container {
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    z-index: 0;
  }
}
// .stars {
//   background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABaCAYAAACv+ebYAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDcvMDMvMTNJ3Rb7AAACnklEQVRoge2XwW3bMBSGPxa9NxtIGzTAW8DdRL7o3A0qb+BrdNIm9QAm0G7gbJBMwB5MoVJNUSRFIXGqHwhkmXr68hOPNH9ljOEt9OlNqBs4RlrrSmtdpdZ/Ti0EGnvtUoqTHFunBVCkuk6d6mbi83rggdteSa5THDeB3+UDO9z2inatXFum1roESuAReAB29vp15n2/gRfgZK+/gIuIXLxgrfUO+Bnzn0fom4ic+pvRVNuB/QrQ/RB6A7bwLjN8b985krO5MsKd0ElwJvgk1AteCPdCYWI5/SutddQxRUTU3DOzG4hd01EKqQnZuaLBITUh4F0CeLYm5CDw6PjuFTjaz9+BLwE1I8VO9StwAEoRaUSkseMHO+aqcWq2qwcdfQCOIvIy8dwDV/c/YL6zvWDbnQ3QuH5hltQEreM1dH/n6g28gT8eWLVUqqVKrb+vtGidFkCR6vp+0uLAba8k1/eRFh1ue0W7dv4sqpaSjGnR1Fy8YNWyY8W0aGpO/c1oqu3AKmlxCL0BW3iXGb637xzJ2VwZ4U7oJDgTfBLqBS+Ee6EQeMpULVFHUVOzPC3aNR2lkJotLbr0vtKiqWlMTcNaaXHQ0QfgaGqcaVG1jNLibGcbYyb/eDIlT6bjyZS+51JqtrS4gTfw/wzWqkKrKrU8fQPR6gKAmDKlPM3x1WkBFKmu0xxf3fZR5jnFdbzjv257JbmOdzx22yvadZzjW7e9ol27HWtVkjEtIubiB2u1Y8W0iJhTfzOe6uvAKmlxCL0FX+FdZvjevnMkd3Plgzuh0+A88EmoH7wM7oVC6AaiVdwuI2Z5WrRrOk4BNVtadOl9pUXENIhpWCstDjr6ABwR40yLaDVKi7Od7U1/Z0pzpjNngtNiaM2WFj8++A+motm0NTqjmwAAAABJRU5ErkJggg==') repeat-x 0 0;
//   width: 150px;
// }
// .stars{
//   &:after{
//     display: table;
//     content: "";
//     clear: both;
//   }
//   &:before{
//     display: table;
//     content: "";
//   }
//   & input[type="radio"] {
//     position: absolute;
//     opacity: 0;
//     filter: alpha(opacity=0);
//   }
//   &
//   label {
//     display: block;
//     width: 30px;
//     height: 30px;
//     margin: 0!important;
//     padding: 0!important;
//     text-indent: -999em;
//     float: left;
//     position: relative;
//     z-index: 10;
//     background: transparent!important;
//     cursor: pointer;
//   }
//   &
//   span {
//     display: block;
//     width: 0;
//     position: relative;
//     top: 0;
//     left: 0;
//     height: 30px;
//     background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABaCAYAAACv+ebYAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDcvMDMvMTNJ3Rb7AAACnklEQVRoge2XwW3bMBSGPxa9NxtIGzTAW8DdRL7o3A0qb+BrdNIm9QAm0G7gbJBMwB5MoVJNUSRFIXGqHwhkmXr68hOPNH9ljOEt9OlNqBs4RlrrSmtdpdZ/Ti0EGnvtUoqTHFunBVCkuk6d6mbi83rggdteSa5THDeB3+UDO9z2inatXFum1roESuAReAB29vp15n2/gRfgZK+/gIuIXLxgrfUO+Bnzn0fom4ic+pvRVNuB/QrQ/RB6A7bwLjN8b985krO5MsKd0ElwJvgk1AteCPdCYWI5/SutddQxRUTU3DOzG4hd01EKqQnZuaLBITUh4F0CeLYm5CDw6PjuFTjaz9+BLwE1I8VO9StwAEoRaUSkseMHO+aqcWq2qwcdfQCOIvIy8dwDV/c/YL6zvWDbnQ3QuH5hltQEreM1dH/n6g28gT8eWLVUqqVKrb+vtGidFkCR6vp+0uLAba8k1/eRFh1ue0W7dv4sqpaSjGnR1Fy8YNWyY8W0aGpO/c1oqu3AKmlxCL0BW3iXGb637xzJ2VwZ4U7oJDgTfBLqBS+Ee6EQeMpULVFHUVOzPC3aNR2lkJotLbr0vtKiqWlMTcNaaXHQ0QfgaGqcaVG1jNLibGcbYyb/eDIlT6bjyZS+51JqtrS4gTfw/wzWqkKrKrU8fQPR6gKAmDKlPM3x1WkBFKmu0xxf3fZR5jnFdbzjv257JbmOdzx22yvadZzjW7e9ol27HWtVkjEtIubiB2u1Y8W0iJhTfzOe6uvAKmlxCL0FX+FdZvjevnMkd3Plgzuh0+A88EmoH7wM7oVC6AaiVdwuI2Z5WrRrOk4BNVtadOl9pUXENIhpWCstDjr6ABwR40yLaDVKi7Od7U1/Z0pzpjNngtNiaM2WFj8++A+motm0NTqjmwAAAABJRU5ErkJggg==') repeat-x 0 -60px;
//     -webkit-transition: -webkit-width 0.5s;
//     -moz-transition: -moz-width 0.5s;
//     -ms-transition: -ms-width 0.5s;
//     -o-transition: -o-width 0.5s;
//     transition: width 0.5s;
//   }
// }

// .stars input[type="radio"].star-5:checked ~ span {
//   width: 100%;
// }
// .stars input[type="radio"].star-4:checked ~ span {
//   width: 80%;
// }
// .stars input[type="radio"].star-3:checked ~ span {
//   width: 60%;
// }
// .stars input[type="radio"].star-2:checked ~ span {
//   width: 40%;
// }
// .stars input[type="radio"].star-1:checked ~ span {
//   width: 20%;
// }

// .stars label:hover ~ span {
//   background-position: 0 -30px;
// }
// .stars label.star-5:hover ~ span {
//   width: 100% !important;
// }
// .stars label.star-4:hover ~ span {
//   width: 80% !important;
// }
// .stars label.star-3:hover ~ span {
//   width: 60% !important;
// }
// .stars label.star-2:hover ~ span {
//   width: 40% !important;
// }
//  .stars label.star-1:hover ~ span {
//   width: 20% !important;
// }

// Loader
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: #ffffff;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: "";
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: rgba(0, 0, 0, 1);
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: rgba(0, 0, 0, 1);
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.dropdown-icon {
  & span {
    vertical-align: middle;
  }
}

.lession-container {
  & .back-btnarrow {
    opacity: 0;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  &:hover .back-btnarrow {
    opacity: 1;
  }
}

// .video-player {
//   &
//   .video-controls{
//     opacity: 0;
//   }
//  &:hover
//   .video-controls{
//     opacity: 1;
//  }
//  & .top-icon{
//   opacity: 0;
//   -webkit-transition: all .5s ease-in-out;
//     transition: all .5s ease-in-out;
//  }
//  &:hover .top-icon{
//   opacity: 1;
//  }
//  &
//  .play-btngroup{
//   opacity: 0;
//   -webkit-transition: all .5s ease-in-out;
//     transition: all .5s ease-in-out;
//  }
//  &:hover
//  .play-btngroup{
//   opacity: 1;
//  }
// }
.video-controls {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.top-header {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
.dropdown-bg {
  backdrop-filter: blur(28px);
  background: rgba(0, 0, 0, 0.6);
}
.play-filter {
  backdrop-filter: blur(9px);
}
.progress-video {
  transition: width 0.5s ease;
}
.player-btn {
  backdrop-filter: blur(48px);
}
.range-wrapper {
  display: none;
  cursor: pointer;
}
.video-progressmain {
  &:hover .range-wrapper {
    display: block;
  }
}
.card-list .card-list-item .gradient1 {
  background: linear-gradient(
    139.59deg,
    #4b73ff 5.27%,
    rgba(101, 135, 255, 0.3) 98.55%
  );
}
.card-list .card-list-item:nth-child(2) .gradient1 {
  background: linear-gradient(
    139.59deg,
    #da8aff 5.27%,
    rgba(218, 138, 255, 0.36) 98.55%
  );
}
.card-list .card-list-item:nth-child(3) .gradient1 {
  background: linear-gradient(
    139.8deg,
    #484848 2.32%,
    #ff7878 2.33%,
    rgba(255, 120, 120, 0.44) 98.56%
  );
}
.homework-container {
  background: linear-gradient(
    110.05deg,
    #4d4d4d 0.98%,
    rgba(186, 186, 186, 0.12) 100%
  );
}
.full-screenmode {
  & iframe {
    width: 100%;
    // position: fixed;
    // inset: 0;
    height: 100vh;
    // z-index: 99;
  }
  video {
    width: 100%;
    height: 100vh;
  }
}
.notes-container {
  backdrop-filter: blur(56px);
}
.video-progressmain {
  & input[type="range"] {
    -webkit-appearance: none;
    background-color: transparent;
    height: 4px;
    // overflow: hidden;
    width: 100%;
    border-radius: 0;
  }
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 20px;
  border-radius: 0;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border: 0;
}

input[type="range"]::-moz-range-thumb {
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border: 0;
}

input[type="range"]::-moz-range-track {
  background-color: #ddd;
  border-radius: 0;
}
input[type="range"]::-moz-range-progress {
  background-color: #666;
  height: 20px;
  border-radius: 50%;
}
input[type="range"]::-ms-fill-upper {
  background-color: #ddd;
}
input[type="range"]::-ms-fill-lower {
  background-color: #666;
}
.large-image {
  & span {
    display: block !important;
  }
  & img {
    width: 100% !important;
    height: auto !important;
  }
}
// Custom ScrollBAr
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #f9c933;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.custom-x-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.custom-x-scroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.custom-track::-webkit-scrollbar-track {
  background-color: darkgrey;
  border-radius: 10px;
}
@media (max-width: 1024px) {
  .custom-x-scroll::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
// .homework-modal{
//   backdrop-filter: blur(11px);
// }
.translate-box {
  transform: translateX(-50%);
}
.volume-filter {
  backdrop-filter: blur(15px);
}
input[type="range"][orient="vertical"] {
  writing-mode: bt-lr; /* IE */
  -webkit-appearance: slider-vertical; /* Chromium */
}
.text-shadow {
  text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.8);
}
.full-video {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
#pre-loader {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#pre-loader svg {
  width: 140px;
}

#pre-loader path {
  stroke-dasharray: 2030;
  stroke-dashoffset: 2030;
  animation: logo-animation 2s ease-in-out alternate infinite;
}

@keyframes logo-animation {
  to {
    stroke-dashoffset: 0px;
  }
}
.filter-bg {
  backdrop-filter: blur(15px);
}
.filter-enroll {
  backdrop-filter: blur(50px);
}

progress::-webkit-progress-bar {
  border-radius: 0;
}
progress::-webkit-progress-value {
  border-radius: 0;
}
progress::-moz-progress-bar {
  border-radius: 0;
}
.certificate-modal {
  & span {
    display: block !important;
  }
}
.modal-blur {
  backdrop-filter: blur(9px);
}
.lock-screen {
  transform: translate(-50%, -50%);
}
.transform-screen {
  transform: translateX(-50%);
}
.expand {
  & span {
    vertical-align: text-top;
  }
}
.dropdown-bg {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(30px);
}
.closeMobileBtn {
  transform: translateX(-50%);
}

.gradient-back {
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}
.blur-30 {
  backdrop-filter: blur(30px);
}
.caption-main {
  transition: all ease-out 0.3s;
}
.caption-maineff {
  transition: all ease-in-out 1s;
}
.caption-icon {
  & span {
    vertical-align: middle;
  }
}

// .main-videowrapper{

// .hover-effect{
//   transition: all .2s ease-in-out;
//   height:0;
//   opacity:0;
// }
// &:hover .hover-effect{
//   background:rgba(0,0,0,.4);
//   height:100%;
//   opacity:1;
// }
// }
video::-webkit-media-controls {
  display: none !important;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}
.font-inter {
  font-family: "Inter", sans-serif;
}
.notes-detail {
  transition: all 0.2s ease-in-out;
}
.notes-detail:hover {
  -webkit-line-clamp: initial;
}

.heart {
  width: 80px;
  height: 65px;
  background: url("../images/heartScaled.png") no-repeat;
  background-position: 13px 20px;
  cursor: pointer;
  transition: background-position 1s steps(28);
  transition-duration: 0s;
  background-size: cover;

  &.is-active {
    transition-duration: 1s;
    @media (min-width: 640px) {
      background-position: -1802px 20px;
    }
    background-position: -1802px 20px;
  }
}

// @media (min-width: 1024px){
//   .heart {

//   }
// }

.play-btngroup,
#player-control {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.star-rating {
  direction: rtl;
  display: inline-block;
  padding: 10px 0;
  cursor: default;

  input[type="radio"] {
    display: none;
  }

  label {
    color: #bbb;
    font-size: 1.5rem;
    padding: 0 3px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  label:hover,
  label:hover ~ label,
  input[type="radio"]:checked ~ label {
    color: #f9c933;
  }
}

.bottom-gradientEffect {
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0) 0%,
    rgba(0, 0, 0, 0) 0.01%,
    #000000 100%
  );
}

.infinite-wordSpace {
  word-spacing: 999999999px;
}

.radial-gradient {
  background: radial-gradient(50% 50% at 50.14% 50%, #3a3a3a 0%, #201f1f 100%);
}

#courseflix {
  .swiper {
    padding-bottom: 30px !important;
  }
  // .swiper-slide {
  //   width: 260px !important;
  //   margin-right: 15px !important;
  // }
  .swiper-pagination {
    width: 120px !important;
    height: 20px !important;
    position: absolute !important;
    bottom: 0 !important;
    z-index: 10000 !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  .swiper-pagination-bullet {
    background-color: #c4c4c4 !important;
    opacity: 1 !important;
    width: 10px !important;
    height: 10px !important;
  }

  .swiper-pagination-bullet-active {
    background-color: #f9c933 !important;
  }
  .swiper-slide:last-child {
    padding-right: 40px !important;
  }

  .courseflix-header {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42)), url("../images/courseflixBg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.category-icon {
  & span {
    display: block !important;
  }
  & img {
    width: 22px !important;
    height: 22px !important;
  }
}
.card-img{
  & span{
    display: block !important;
  }
}